import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "./firebase";
import { Button, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

function AdminToolbar({ user }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAutoFilling, setIsAutoFilling] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isResettingMatchups, setIsResettingMatchups] = useState(false);

  useEffect(() => {
    const fetchAdminValue = async () => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        setIsAdmin(userDoc.data().admin || false);
      }
    };

    fetchAdminValue();
  }, [user]);

  const handleResetSquads = async () => {
    setIsResetting(true);
    console.log("Resetting squads...");
    try {
      // 1. Delete all documents in the "squads" collection
      const squadsRef = collection(db, "squads");
      const squadsSnapshot = await getDocs(squadsRef);
      const deleteSquadsPromises = squadsSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deleteSquadsPromises);

      // 2. Delete all documents in the "squadUsers" collection
      const squadUsersRef = collection(db, "squadUsers");
      const squadUsersSnapshot = await getDocs(squadUsersRef);
      const deleteSquadUsersPromises = squadUsersSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deleteSquadUsersPromises);

      // 3. Generate new squads
      const teams = [
        "MISS",
        "MS ST",
        "LA ST",
        "NASH",
        "TENN",
        "GA",
        "TEX",
        "TX AM",
        "AUB",
        "FL",
        "OK",
        "MO",
        "AL",
        "ARK",
        "SC",
        "KY",
      ];
      const positions = {
        QB: "Quarterbacks",
        WR: "Wide Receivers",
        TE: "Tight Ends",
        OL: "Offensive Line",
        RB: "Running Backs",
        DL: "Defensive Line",
        DB: "Defensive Backs",
        S: "Safeties",
        K: "Kickers",
        ST: "Special Teams",
      };

      for (const team of teams) {
        for (const [abbreviation, fullName] of Object.entries(positions)) {
          const squadName = `${fullName}`;
          const logo = `${team.replaceAll(" ", "").toLowerCase()}.jpg`;
          const currentPoints = (Math.random() * 45).toFixed(1);
          const projected = (Math.random() * 45).toFixed(1);

          await addDoc(collection(db, "squads"), {
            squadName,
            logo,
            team: team,
            position: abbreviation,
            currentPoints: parseFloat(currentPoints),
            projected: parseFloat(projected),
          });
        }
      }

      console.log("Squads reset successfully!");
    } catch (error) {
      console.error("Error resetting squads:", error);
    } finally {
      setIsResetting(false);
    }
  };

  const handleAutoFillAllRosters = async () => {
    setIsAutoFilling(true);

    try {
      // 1. Fetch all leagueUsers documents
      const leagueUsersRef = collection(db, "leagueUsers");
      const leagueUsersSnapshot = await getDocs(leagueUsersRef);

      // 2. Group leagueUsers by leagueId
      const leagues = leagueUsersSnapshot.docs.reduce((acc, doc) => {
        const { leagueId } = doc.data();
        if (!acc[leagueId]) {
          acc[leagueId] = [];
        }
        acc[leagueId].push(doc.data());
        return acc;
      }, {});

      // 3. Autofill rosters for each league
      const autoFillPromises = Object.entries(leagues).map(
        async ([leagueId, users]) => {
          await autoFillRostersForLeague(users, leagueId);
        }
      );

      await Promise.all(autoFillPromises);
      console.log("Auto-filled all rosters successfully!");
    } catch (error) {
      console.error("Error auto-filling all rosters:", error);
    } finally {
      setIsAutoFilling(false);
    }
  };

  // Helper function to auto-fill rosters for a single league
  const autoFillRostersForLeague = async (users, leagueId) => {
    const positions = [
      "QB",
      "WR",
      "TE",
      "OL",
      "RB",
      "DL",
      "DB",
      "S",
      "K",
      "ST",
    ];

    for (const position of positions) {
      try {
        // 1. Get N random squads for the position (N = number of users in the league)
        const squadsRef = collection(db, "squads");
        const squadsQuery = query(
          squadsRef,
          where("position", "==", position),
          orderBy("currentPoints", "desc")
        );
        const squadsSnapshot = await getDocs(squadsQuery);

        if (squadsSnapshot.docs.length < users.length) {
          console.warn(
            `Not enough squads for position ${position} in league ${leagueId}. Skipping.`
          );
          continue;
        }

        const availableSquads = squadsSnapshot.docs.slice(0, users.length);
        const shuffledSquads = shuffleArray(availableSquads);

        // 2. Assign squads to users randomly
        await Promise.all(
          users.map(async (user, index) => {
            const squad = shuffledSquads[index];
            const squadId = squad.id;

            const squadUsersRef = collection(db, "squadUsers");
            const userSquadQuery = query(
              squadUsersRef,
              where("userId", "==", user.userId),
              where("position", "==", position),
              where("leagueId", "==", leagueId)
            );
            const userSquadSnapshot = await getDocs(userSquadQuery);

            if (!userSquadSnapshot.empty) {
              console.log(
                `User ${user.userId} already has a squad for position ${position} in league ${leagueId}, skipping.`
              );
              return;
            }

            await addDoc(collection(db, "squadUsers"), {
              userId: user.userId,
              leagueId: leagueId,
              squadId: squadId,
              position: position,
              createdAt: serverTimestamp(),
            });

            console.log(
              `Added squad ${squad.data().squadName} to user ${
                user.userId
              }'s roster in league ${leagueId}.`
            );
          })
        );
      } catch (error) {
        console.error(
          `Error auto-filling position ${position} in league ${leagueId}:`,
          error
        );
      }
    }
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const handleGenerateMatchups = async () => {
    setIsResettingMatchups(true);
    console.log("Resetting matchups...");
    try {
      // first, delete all old matchups
      const matchupsData = collection(db, "matchups");
      const matchupsSnapshot = await getDocs(matchupsData);
      const deleteMatchupsPromises = matchupsSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deleteMatchupsPromises);
      console.log("deleted all matchups");

      // 1. Get all leagues
      const leaguesRef = collection(db, "leagues");
      const leaguesSnapshot = await getDocs(leaguesRef);

      for (const leagueDoc of leaguesSnapshot.docs) {
        const leagueId = leagueDoc.id;

        // 2. Get all users in the league
        const leagueUsersRef = collection(db, "leagueUsers");
        const q = query(leagueUsersRef, where("leagueId", "==", leagueId));
        const usersSnapshot = await getDocs(q);
        let users = usersSnapshot.docs.map((doc) => doc.data().userId);

        // 4. Generate matchups for each week
        const numWeeks = 12;
        const numUsers = users.length;

        for (let week = 1; week <= numWeeks; week++) {
          shuffleArray(users);

          // Create pairs for the matchups
          const pairs = [];
          for (let i = 0; i < numUsers; i += 2) {
            if (i + 1 < numUsers) {
              pairs.push([users[i], users[i + 1]]);
            }
          }

          // Create matchup documents for the current week
          for (const [user1Id, user2Id] of pairs) {
            await addDoc(collection(db, "matchups"), {
              leagueId: leagueId,
              week: week,
              user1Id: user1Id,
              user2Id: user2Id,
            });
          }

          // Rotate users for the next week
          users = [users[users.length - 1], ...users.slice(0, -1)];
        }
      }

      console.log("Matchups generated successfully!");
    } catch (error) {
      console.error("Error generating matchups:", error);
    } finally {
      setIsResettingMatchups(false);
    }
  };

  return (
    user &&
    isAdmin && (
      <Box sx={{ mt: 5 }}>
        <Typography variant="h6" gutterBottom align="center">
          Admin Toolbar
        </Typography>
        <Button
          sx={{ margin: "10px", width: "95%" }}
          variant="contained"
          onClick={handleResetSquads}
          disabled={isResetting}
        >
          {isResetting ? "Resetting..." : "Reset Squads"}
        </Button>
        <Button
          sx={{ margin: "10px", width: "95%" }}
          variant="contained"
          onClick={handleAutoFillAllRosters}
          disabled={isAutoFilling}
        >
          {isAutoFilling ? "Auto-filling..." : "Auto-Fill All Rosters"}
        </Button>
        <Button
          sx={{ margin: "10px", width: "95%" }}
          variant="contained"
          onClick={handleGenerateMatchups}
          disabled={isResettingMatchups}
        >
          {isResettingMatchups ? "Resetting Matchups..." : "Reset Matchups"}
        </Button>
        <Button
          sx={{ margin: "10px", width: "95%" }}
          variant="contained"
          component={Link}
          to="/adminGenSeason"
        >
          Generate Season Dates
        </Button>
        <Button
          sx={{ margin: "10px", width: "95%" }}
          variant="contained"
          component={Link}
          to="/rosterExperimental"
        >
          Experimental Roster Page
        </Button>
      </Box>
    )
  );
}

export default AdminToolbar;
